import React from 'react';
//import ReactDOM from 'react-dom';

import {  BrowserRouter, Routes,Outlet , Route } from 'react-router-dom'
import {Grid,Container} from '@mui/material';

import Header from './components/header/Header'
import Footer from './components/footer/Footer'

import Page404 from './views/Page404' 
import Home from './views/Home'
import Page from './views/Page'



const Layout=() => (
  <> 
    <Header />
    <Container>
      <Outlet />
    </Container>
    <Footer />
  </>
)

const Contact = Page404;


function App() {
  return (
      <BrowserRouter>
          <Routes >
            <Route path="/" element={<Layout />}>   
              <Route index element={<Home/>}/>
              <Route path='contact' element={<Contact/>}/>
              <Route path='feature/:id' element={<Page/>}/>
              <Route path='*' element={<Page404/>}/>
            </Route>
          </Routes>
      </BrowserRouter>
  );
}

export default App;
