import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './store/reducers/rootReducer'
import { Provider,useSelector } from 'react-redux'

import Loader from "./components/other/Loader"
import Error from "./views/error"




const store = createStore(rootReducer,
  compose(
      //middleware,
      //reactReduxFirebase(fbConfig,{attachAuthIsReady:true}),
  )
);

if(!store.getState().pageDetails.isLoaded)
{
  fetch('https://pages.blizo.co/').then(async (response)=>{

  try 
  {
    const jsonData = await response.json();
    if(jsonData.pages)
      store.dispatch({ type: 'LOAD_PAGES',pages:jsonData.pages });
    else
      store.dispatch({ type: 'ERROR'});

  }
  catch (error) 
  {
    store.dispatch({ type: 'ERROR'});

  }
    
  }).catch(()=>{
    store.dispatch({ type: 'ERROR'});
  });
}


const IsLoaded=({children})=>{

  const {isLoaded,error} = useSelector(state => state.pageDetails);
  if(error)
    return <Error/>
  else if(isLoaded)
    return children
  else
    return <Loader/>
}

ReactDOM.render(
  <Provider store={store}>
    <IsLoaded>
      <App />
    </IsLoaded>
  </Provider>, document.getElementById('root'));
  
//serviceWorker.unregister();
