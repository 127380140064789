const initState ={
    
}

const detailsReducer = (state = initState, action) => {
    switch(action.type) {
        case "LOAD_PAGES": 
        
            return {
                ...state,
                isLoaded: true,
                pages:action.pages ? action.pages : [],
            }
        case "ERROR": 
        
            return {
                ...state,
                isLoaded: true,
                error: true,
            }
        
        default: 
            return state;
    }

    return state;
}

export default detailsReducer;