import React, {Component } from 'react'
import {Box,CircularProgress} from '@mui/material';


const Loader = (props) => {
    return <Box sx={{
        alignContent: "center;",
        textAlign: "center;",
        paddingTop: "50px;",
        paddingBottom: "150px;",
    }}>
        <CircularProgress  sx={{color:"#19A78E"}}/>
        <h4>Loading</h4>

    </Box>
}

export default (Loader);