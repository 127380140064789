import React from 'react'


const View = () => {

    return (
            <div style={{textAlign:"center"}}>
                <h1><strong>404.</strong> Could not find page.</h1>
                <p>We could not find the page you were looking for.</p>
            </div>
        )
    
}
export default View
