import React from 'react'

import { useSelector, useDispatch } from 'react-redux';
//import apiAccess from "../store/api/Access"
import {Grid,Container} from '@mui/material';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { Link,useMatch } from 'react-router-dom';


const Index = ({}) => {
  const dispatch = useDispatch();
  const pageDetails = useSelector(state => state.pageDetails);

console.log(pageDetails)
    return (
      <main>
        <Grid container spacing={2}>

          
        {pageDetails.pages && Object.keys(pageDetails.pages).map((item,index)=>{
        return(<Grid item xs={12}  sm={12} md={6} lg={3} xl={3}  spacing={2} >


          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {pageDetails.pages[item].name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {pageDetails.pages[item].excerpt ? pageDetails.pages[item].excerpt : ""}
              </Typography>
            </CardContent>
            <CardActions>
              <Button  component={Link} to={"/feature/"+item} size="small"  sx={{textDecoration:"none", color:"#334",borderColor:"#335"}} variant="outlined">Learn More</Button>
            </CardActions>
          </Card>
        </Grid>)

})

}
        </Grid>
      </main>
    );
}


export default (Index);