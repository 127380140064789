import React from 'react'


const View = () => {

    return (
            <div style={{textAlign:"center"}}>
                <h1><strong>Error.</strong> Could not load page</h1>
            </div>
        )
    
}
export default View
