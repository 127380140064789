import React from 'react'
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import {Typography,Toolbar,Container} from '@mui/material';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import { Link,useMatch } from 'react-router-dom';


const Header = ({user}) => {    
  const  path  = useMatch("/");
  console.log("path")
  console.log(path)

    return (
      <AppBar position="static" sx={{background:"none", boxShadow:"none",color:"#333"}}>
      <Container maxWidth="xl">

        <Toolbar disableGutters>

        {!path ? <Link to="/"><ArrowBackIos sx={{textDecoration:"none", color:"#334"}} /> </Link> : null }


        <Typography
            component={Link}
            to="/"
            variant="h5"
            noWrap
            sx={{ flexGrow: 1, textAlign:"center",textDecoration:"none", color:"#334"}}
          >
            Blizo
          </Typography>
          
        <Button href="https://dashboard.blizo.co" sx={{textDecoration:"none", color:"#334",borderColor:"#335"}} variant="outlined">Login</Button>


        </Toolbar>
        </Container>
      </AppBar>);
}

export default /*connect(mapStateToProps)*/(Header)