import React from 'react'
import { Routes, Route, useParams } from 'react-router-dom';
import {Toolbar} from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';
//import apiAccess from "../store/api/Access"
import parse from 'html-react-parser';


import { Link,useMatch } from 'react-router-dom';

import Page404 from "./Page404"


const Index = ({}) => {

    let { id } = useParams();
    const dispatch = useDispatch();
    const {pages} = useSelector(state => state.pageDetails);

    if(!(id in pages))
    {
        return <Page404 />
    }
    const page = pages[id]

    console.log(page)


        return (
            <main>
                <h1>
                {page.name ? parse(page.name) : null}
                </h1>
                {page.details ? parse(page.details) : null}
            </main>
        );
}




export default /*compose(
    connect(mapStateToProps)
)*/(Index);